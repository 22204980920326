<template>
  <div class="container">
    <!--top-->
    <top>
      <template v-slot:textRight>
        <div>
          <div class="returnBtn" v-if="isShow" @click="$router.push('/classroom/list')">
            <i class="el-icon-back"></i>
          </div>
        </div>
      </template>
    </top>
    <keep-alive include="list">
      <router-view />
    </keep-alive>
  </div>
</template>


<script>
export default {
    name: 'classroom',
    components:{
        top: () => import('../county/top.vue')
    },
    data(){
      return {
        isShow:false
      }
    },
    watch:{
      $route(to,from){
        if(to.path == '/classroom/info') this.isShow = true
        else this.isShow = false
      }
    },
    created(){
      if(this.$route.path == '/classroom/info'){
        this.isShow = true
      }
    }
}
</script>


<style scoped lang="scss">
.container {
  height: auto;
  min-height: 100%;
  position: relative;
  background: url(../../assets/img/body.png) center no-repeat;
  background-size: cover;
}
.returnBtn{
  color:#fff;
  font-size:0.3rem;
  cursor: pointer;
}
</style>


